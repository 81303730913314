import axios from "axios";
import {
  getSession
} from "@/utils/util";

export const apiUrl = process.env.VUE_APP_BASE_URL
export const picUrl = process.env.VUE_APP_PIC_URL

//用来拦截用的
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
//创建一个单例
const http = axios.create({
  baseURL: apiUrl,
  headers: {"Content-Type":"application/json;charset=utf-8"},
})

//拦截器  -请求拦截
http.interceptors.request.use(config=>{
  //部分接口需要token
  let token = getSession('token')
  if(token){
    config.headers.token=token;
    config.headers = {
      'Authorization':token
    }
  }
  return config;
},err=>{
  return Promise.reject(err)
})

//拦截器  -响应拦截
http.interceptors.response.use(res=>{
  if(res.status === 200){
    if (typeof res.data !== 'object') {
      return Promise.reject(res)
    }
    return res.data
  }else{
    return Promise.reject(res.data)
  }
},err=>{
  return Promise.reject(err)
});

//整体导出
export default http;
