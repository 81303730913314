import {createRouter, createWebHashHistory} from 'vue-router'
import {getSession, removeSession} from "@/utils/util";
import {AdminInfo} from "@/api/admin";

const routes = [
  {
    path: '/',
    name: 'Waymon',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import ('../views/Home.vue'),
    meta: {
      title: '首页'
    }
  }, {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      title: '登录'
    }
  }, {
    path: '/Admin',
    name: 'Admin',
    component: () => import ('../views/Admin.vue'),
    meta: {
      title: '管理员',
    }
  }, {
    path: '/AdminAdd',
    name: 'AdminAdd',
    component: () => import ('../views/AdminAdd.vue'),
    meta: {
      title: '添加管理员'
    }
  }, {
    path: '/AdminEdit',
    name: 'AdminEdit',
    component: () => import ('../views/AdminEdit.vue'),
    meta: {
      title: '管理员编辑'
    }
  },
  {
    path: '/Member',
    name: 'Member',
    component: () => import ('../views/Member.vue'),
    meta: {
      title: '用户列表',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/Order',
    name: 'Order',
    component: () => import ('../views/Order.vue'),
    meta: {
      title: '订单管理',
    }
  },
  {
    path: '/MemberEdit',
    name: 'MemberEdit',
    component: () => import ('../views/MemberEdit.vue'),
    meta: {
      title: '用户编辑'
    }
  },
  {
    path: '/Role',
    name: 'Role',
    component: () => import ('../views/Role.vue'),
    meta: {
      title: '角色管理',
    }
  },
  {
    path: '/RoleAdd',
    name: 'RoleAdd',
    component: () => import ('../views/RoleAdd.vue'),
    meta: {
      title: '添加角色'
    }
  },
  {
    path: '/RoleEdit',
    name: 'RoleEdit',
    component: () => import ('../views/RoleEdit.vue'),
    meta: {
      title: '角色编辑'
    }
  },
  {
    path: '/System',
    name: 'System',
    component: () => import ('../views/System.vue'),
    meta: {
      title: '系统设置'
    }
  },{
    path: '/Message',
    name: 'Message',
    component: () => import ('../views/Message.vue'),
    meta: {
      title: '投诉列表',
    }
  },{
    path: '/Banner',
    name: 'Banner',
    component: () => import ('../views/Banner.vue'),
    meta: {
      title: '轮播图列表',
    }
  },{
    path: '/BannerAdd',
    name: 'BannerAdd',
    component: () => import ('../views/BannerAdd.vue'),
    meta: {
      title: '添加轮播图',
    }
  },{
    path: '/Amount',
    name: 'Amount',
    component: () => import ('../views/Amount.vue'),
    meta: {
      title: '账户列表',
    }
  },{
    path: '/Coin',
    name: 'Coin',
    component: () => import ('../views/Coin.vue'),
    meta: {
      title: '币种列表',
    }
  },{
    path: '/CoinAdd',
    name: 'CoinAdd',
    component: () => import ('../views/CoinAdd.vue'),
    meta: {
      title: '添加币种',
    }
  },{
    path: '/Candles',
    name: 'Candles',
    component: () => import ('../views/Candles.vue'),
    meta: {
      title: '币种行情',
    }
  },{
    path: '/Authen',
    name: 'Authen',
    component: () => import ('../views/Authen.vue'),
    meta: {
      title: '实名认证',
    }
  },{
    path: '/AuthenEdit',
    name: 'AuthenEdit',
    component: () => import ('../views/AuthenEdit.vue'),
    meta: {
      title: '编辑实名',
    }
  },{
    path: '/Withdraw',
    name: 'Withdraw',
    component: () => import ('../views/Withdraw.vue'),
    meta: {
      title: '提现列表',
    }
  },{
    path: '/WithdrawEdit',
    name: 'WithdrawEdit',
    component: () => import ('../views/WithdrawEdit.vue'),
    meta: {
      title: '编辑提现',
    }
  },{
    path: '/Search',
    name: 'Search',
    component: () => import ('../views/Search.vue'),
    meta: {
      title: '搜索',
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = getSession('token')
  if (token) { //已经登录
    document.title = to.meta.title
    AdminInfo({

    }).then((res)=>{
      if (res.status == 0) {
        next()
      } else if (res.status == -1) {
        removeSession('token')
        removeSession('admin')
        if (to.name !== "Login") {
          next('/Login')
        }
      }
    })
  } else { // 什么都没有 去授权
    if (to.name !== "Login") {
      next('/Login')
    } else {
      next()
    }
  }
})

export default router
