<template>
  <div class="app">
    <el-container v-if="!state.isLogin" class="container">
      <el-aside width="200px">
        <div class="waymon_aside">
            <div class="waymon_title">管理系统</div>
        </div>
        <el-menu
            v-if="state.menus"
            :default-opends="['1']"
            background-color="#222832"
            text-color="#fff"
            active-text-color="#000000"
            :router="true"
            :unique-opened="true"
            :default-active="state.currentPath"
        >
          <el-sub-menu v-for="(item,index) in state.menus" :index="index">
            <template #title>
              <component :is="item.icon" style="width: 22px;height: 22px; margin-right: 5px"></component>
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item-group v-if="item.subs" v-for="value in item.subs">
              <el-menu-item @click="onMenu" :index="value.index" :title="value.title" :route="value.route">
                {{ value.title }}
              </el-menu-item>
            </el-menu-item-group>
          </el-sub-menu>
        </el-menu>
      </el-aside>
      <el-container v-if="!state.isLogin">
        <el-header height="80px">
          <el-row>
            <el-col :span="22">
                <div class="waymon_header_left">
                  <el-page-header @back="onBack">
                    <template #icon>
                      <el-icon size="20" color="#000000"><Back /></el-icon>
                    </template>
                    <template #title>
                      <span style="color: black">返回</span>
                    </template>
                    <template #content>
                      <span class="text-large font-600 mr-3"> {{state.title}} </span>
                    </template>
                  </el-page-header>
                </div>
            </el-col>
            <el-col :span="2">
              <el-dropdown>
                  <span>
                    <el-row>
                      <el-col :span="20"><div class="waymon_detail">{{state.admin.nickname}}</div></el-col>
                      <el-col :span="4"><img class="waymon_icon" src="../src/assets/set.png"></el-col>
                    </el-row>
                  </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item :icon="Edit" @click="onPassword">修改密码</el-dropdown-item>
                    <el-dropdown-item :icon="SwitchButton" @click="onOut">退出</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-col>
          </el-row>
        </el-header>
         <el-main v-if="!state.isLogin">
          <router-view v-if="isRouterActive" v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive"/>
            </keep-alive>
            <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive"/>
          </router-view>
        </el-main>
      </el-container>
    </el-container>
    <!-- 登录 -->
    <el-container v-else class="container">
      <router-view/>
    </el-container>
  </div>
  <el-backtop/>
</template>
<script setup>
import { ref, reactive, nextTick, provide, onMounted } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex'
import {Edit,SwitchButton} from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus'
import { removeSession } from './utils/util.js'
import {AdminMenu} from "@/api/admin";
const store = useStore()
const route = useRoute()
const router = useRouter()
const isRouterActive = ref(true)
const state = reactive({
  isLogin: false,
  isBack: false,
  title: '首页',
  admin: {},
  menus: [],
  indexs: [],
  currentPath: 'Home',
})
onMounted(() => {
  const admin = store.getters.admin
  if (!admin) {
    state.isLogin = true
    router.push({ path: '/Login' })
  } else {
    state.isLogin = false
    state.admin = admin
    //
    ElNotification({
      title: '欢迎' + admin.username + '登录!',
      dangerouslyUseHTMLString: true,
      message: '登录时间 : ' + admin.last_time + '</br>' + '登录ip : ' + admin.last_ip + '</br>' + '登录次数 : ' + admin.logins,
      position: 'bottom-right',
    })
    //
    AdminMenu({
      role_id: admin.role_id
    }).then((res)=>{
       state.menus = res.data
    })
  }
  router.afterEach((to) => {
    const { title } = to.meta
    state.title = title
  })
})
const onBack = () => {
  router.go(-1)
}
const reload = () => {
  isRouterActive.value = false
  nextTick(() => {
    isRouterActive.value = true
  })
}
provide('reload', {
  reload
})
const onPassword = () => {
    router.push({
      path: '/AdminEdit',
      query: { id: state.admin.id }
    })
}
const onOut = () => {
  removeSession('admin')
  state.isLogin = true
  router.push({ path: '/Login' })
}
</script>
<style>
@import "css/waymon.css";
</style>
